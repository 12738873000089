const state = {
  theme: 'pink'
}

const getters = {
  theme: state => state.theme,
  themeList: () => {
    return [
      {label: '粉色主题', key: 'pink', status: 1, color: '#F4628F'},
      {label: '蓝色主题', key: 'blue', status: 1, color: '#6B75CA'},
      {label: '深色主题', key: 'dark', status: 1, color: '#191a22'}
    ]
  }
}

const actions = {
  switchTheme ({commit}, theme) {
    commit('switchTheme', theme)
  }
}

const mutations = {
  switchTheme (state, theme) {
    state.theme = theme
  }
}

export default {
  state, getters, actions, mutations
}