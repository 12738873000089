export const envs = {
  nk: {
    env: 'hrms_nk',
    webPort: '',
    hostName: 'neikong.e-forward.cn',
    apiAdr: 'https://api.neikong.e-forward.cn',
    remark: 'hrms_nk',
    ipc: '皖ICP备2021009694号'
  },
  nkDemo: {
    env: 'nk_demo',
    webPort: '',
    hostName: 'demo.neikong.e-forward.cn',
    apiAdr: 'https://api.demo.neikong.e-forward.cn',
    remark: 'nk_demo',
    ipc: '皖ICP备2021009694号'
  },
  nktest: {
    env: 'nktest',
    webPort: '',
    hostName: 'test.e-forward.cn',
    apiAdr: 'https://api.test.e-forward.cn',
    // apiAdr: 'http://127.0.0.1:8780',
    remark: 'nktest',
    ipc: '皖ICP备2021009694号'
  },
  aqthxyy: {
    env: 'aqthxyy',
    webPort: '',
    hostName: 'aqthxyy.e-forward.cn',
    apiAdr: 'https://api.aqthxyy.e-forward.cn',
    remark: 'aqthxyy',
    ipc: '皖ICP备2021009694号'
  }
}

const initEnv = () => {
  // 根据前端接口来判断环境
  let env = null
  let hostName = window.location.hostname
  let useEnvs = Object.keys(envs).filter(key => envs[key].hostName === hostName)
  console.log(useEnvs, 'useEnvs')
  let webPort = window.location.port
  if (useEnvs.length > 0) {
    env = envs[useEnvs[0]]
    if (useEnvs.length > 1) {
      let useEnvKey = useEnvs.find(key => envs[key].webPort === webPort)
      console.log(useEnvKey)
      if (useEnvKey) {
        env = envs[useEnvKey]
      }
    }
  }
  console.log(env ? ('环境:' + env.remark) : '获取环境失败, 使用' + envs['nk'].remark)
  return env || envs['nktest']
}

export default initEnv()
