export default [
  {
    path: '/',
    name: 'app',
    component: () => import('@/views/app'),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/app/nullPage')
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home')
      },
      {
        path: '/conf',
        name: 'conf',
        component: () => import('@/views/conf'),
        children: [
          {
            path: 'conf_query',
            name: 'conf.conf_query',
            component: () => import('@/views/conf/conf_query'),
          }
        ]
      },
      {
        path: '/base',
        name: 'base',
        component: () => import('@/views/base'),
        children: [
          {
            path: 'org',
            name: 'base.org',
            component: () => import('@/views/base/org')
          },
          {
            path: 'worker',
            name: 'base.worker',
            component: () => import('@/views/base/worker')
          },
          {
            path: 'bill',
            name: 'base.bill',
            component: () => import('@/views/base/bill')
          },
          {
            path: 'assets_type',
            name: 'base.assets_type',
            component: () => import('@/views/base/assetsType')
          },
          {
            path: 'goods',
            name: 'base.goods',
            component: () => import('@/views/base/goods')
          }
        ]
      },
      {
        path: '/authority',
        name: 'authority',
        component: () => import('@/views/authority'),
        children: [
          {
            path: 'user',
            name: 'authority.user',
            component: () => import('@/views/authority/user'),
          },
          {
            path: 'role',
            name: 'authority.role',
            component: () => import('@/views/authority/role'),
          },
          {
            path: 'menu',
            name: 'authority.menu',
            component: () => import('@/views/authority/menu'),
          },
          {
            path: 'fun',
            name: 'authority.fun',
            component: () => import('@/views/authority/fun')
          },
          {
            path: 'sys_config',
            name: 'authority.sys_config',
            component: () => import('@/views/authority/sysConfig')
          },
          {
            path: 'status',
            name: 'authority.status',
            component: () => import('@/views/status/index')
          },
          {
            path: 'operation_log',
            name: 'authority.operation_log',
            component: () => import('@/views/authority/operationLog')
          }
        ]
      },
      {
        path: '/budget',
        name: 'budget',
        component: () => import('@/views/budget/router'),
        children: [
          {
            path: 'manage',
            name: 'budget.manage',
            component: () => import('@/views/budget/index'),
            props: { type: 'manage' }
          },
          {
            path: 'handle',
            name: 'budget.handle',
            component: () => import('@/views/budget/index'),
            props: { type: 'handle' }
          },
          {
            path: 'details',
            name: 'budget.details',
            component: () => import('@/views/budget/details'),
          }
        ]
      },
      {
        path: '/purchase',
        name: 'purchase',
        component: () => import('@/views/purchase/router'),
        children: [
          {
            path: 'index',
            name: 'purchase.index',
            component: () => import('@/views/purchase/index'),
            props: { type: 'index' }
          },
          {
            path: 'handle',
            name: 'purchase.handle',
            component: () => import('@/views/purchase/index'),
            props: { type: 'handle' }
          },
          {
            path: 'details',
            name: 'purchase.details',
            component: () => import('@/views/purchase/details'),
          },
          {
            path: 'contract',
            name: 'purchase.contract',
            component: () => import('@/views/purchase/contract')
          },
          {
            path: 'implement',
            name: 'purchase.implement',
            component: () => import('@/views/purchase/implement')
          }
        ]
      },
      {
        path: '/purchase_method',
        name: 'purchase_method',
        component: () => import('@/views/purchase_method/router'),
        children: [
          {
            path: 'index',
            name: 'purchase_method.index',
            component: () => import('@/views/purchase_method/index'),
            props: { type: 'index' }
          },
          {
            path: 'handle',
            name: 'purchase_method.handle',
            component: () => import('@/views/purchase_method/index'),
            props: { type: 'handle' }
          },
          {
            path: 'details',
            name: 'purchase_method.details',
            component: () => import('@/views/purchase_method/details'),
          }
        ]
      },
      {
        path: '/repair',
        name: 'repair',
        component: () => import('@/views/repair/index'),
        children: [
          {
            path: 'manage',
            name: 'repair.manage',
            component: () => import('@/views/repair/manage')
          },
          {
            path: 'apply',
            name: 'repair.apply',
            component: () => import('@/views/repair/apply')
          },
          {
            path: 'apply_record',
            name: 'repair.apply_record',
            component: () => import('@/views/repair/applyRecord')
          }
        ]
      },
      {
        path: '/scrap',
        name: 'scrap',
        component: () => import('@/views/scrap/index'),
        children: [
          {
            path: 'apply',
            name: 'scrap.apply',
            component: () => import('@/views/scrap/apply')
          },
          {
            path: 'record',
            name: 'scrap.record',
            component: () => import('@/views/scrap/record')
          }
        ]
      },
      {
        path: '/accept',
        name: 'accept',
        component: () => import('@/views/accept/index'),
        children: [
          {
            path: 'manage',
            name: 'accept.manage',
            component: () => import('@/views/accept/manage')
          },
          {
            path: 'my',
            name: 'accept.my',
            component: () => import('@/views/accept/my')
          }
        ]
      },
      {
        path: '/config',
        name: 'config',
        component: () => import('@/views/config/index'),
        children: [
          {
            path: 'select',
            name: 'config.select',
            component: () => import('@/views/config/select')
          }
        ]
      },
      {
        path: '/assets',
        name: 'assets',
        component: () => import('@/views/assets/index'),
        children: [
          {
            path: 'manage',
            name: 'assets.manage',
            component: () => import('@/views/assets/manage')
          },
          {
            path: 'manage_gy',
            name: 'assets.manage_gy',
            component: () => import('@/views/assets/manageGy')
          },
          {
            path: 'manage_dw',
            name: 'assets.manage_dw',
            component: () => import('@/views/assets/manageDw')
          },
          {
            path: 'check',
            name: 'assets.check',
            component: () => import('@/views/assets/check')
          },
          {
            path: 'repair',
            name: 'assets.repair',
            component: () => import('@/views/assets/repair')
          },
          {
            path: 'in',
            name: 'assets.in',
            component: () => import('@/views/assets/in')
          },
          {
            path: 'ledger',
            name: 'assets.ledger',
            component: () => import('@/views/assets/ledger')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => /iphone|ios|android|ipad/i.test(navigator.userAgent) ? import('@/views/login/mobile') : import('@/views/login/index')
  }
]
