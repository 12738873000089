import {
  menuRequest,
  funRequest,
  roleRequest,
  userRequest,
  workerRequest,
  orgRequest,
  orgTypeRequest,
  configsRequest,
  assetsTypeRequest,
  assetsRequest,
  goodsRequest
} from '@/api'

import {
  tools
} from '@/fmlib'

import Config from '@/config'

const dealData = (data, key, label) => {
  let newData = []
  data.forEach((item) => {
    let itemLabel = null

    if (Array.isArray(label)) {
      itemLabel = label.map(v => item[v]).join(' ')
    } else {
      itemLabel = item[label]
    }

    newData.push({
      key: item[key],
      label: itemLabel,
      data: item
    })
  })
  return newData
}

const state = {
  // 正在加载的数据
  baseDataLoading: {},
  // 已加载数据
  baseDataLoaded: {},
  // 菜单
  menuList: [],
  // 功能
  funList: [],
  // 角色
  roleList: [],
  // 菜单类型
  menuTypeList: [{key: 'menu', label: '菜单'}, {key: 'sys_page', label: '系统页面'}, {key: 'out_page', label: '外部链接'}],
  // 客户端类型
  clientTypeList: [{key: 'web', label: '电脑'}, {key: 'wap', label: '手机'}, {key: 'pda', label: 'PDA设备'}],
  // 当前客户端类型
  clientType: navigator.userAgent.match(/Android|iPhone|iPad|iPod/) === null ? 'web' : 'wap',
  // user
  userList: [],
  // orgList
  orgList: [],
  // orgTree
  orgTree: [],
  // workerList
  workerList: [],
  // allWorkerList
  allWorkerList: [],
  // orgType
  orgTypeList: [],
  selectDatas: {},
  sysConfig: {},
  assetsTypeList: [],
  assetsList: [],
  goodsList: []
}

const loadData = async function ({commit, getters, reLoad, dataKey, request, mutation, dealSourceData, parm}) {
  if ((reLoad || !getters.baseDataLoaded[dataKey]) && !getters.baseDataLoading[dataKey]) {
    commit('updateBaseDataLoading', {key: dataKey, value: true})
    let dataList = await request(parm)
    commit(mutation, dealSourceData(dataList))
    commit('updateBaseDataLoaded', dataKey)
    commit('updateBaseDataLoading', {key: dataKey, value: false})
    return getters[dataKey]
  } else if (getters.baseDataLoading[dataKey]) {
    while (getters.baseDataLoading[dataKey]) {
      await tools.sleep(200)
    }
    return getters[dataKey]
  } else {
    return Promise.resolve(getters[dataKey])
  }
}

const getters = {
  baseDataLoading: state => state.baseDataLoading,
  baseDataLoaded: state => state.baseDataLoaded,
  menuList: state => state.menuList,
  funList: state => state.funList,
  roleList: state => state.roleList,
  menuTypeList: state => state.menuTypeList,
  clientTypeList: state => state.clientTypeList,
  clientType: state => state.clientType,
  userList: state => state.userList,
  orgList: state => state.orgList,
  orgTree: state => state.orgTree,
  workerList: state => state.workerList,
  allWorkerList: state => state.allWorkerList,
  appraisalStatusList: state => state.appraisalStatusList,
  appraisalTypeList: state => state.appraisalTypeList,
  appraisalGroupTypeList: state => state.appraisalGroupTypeList,
  appraisalGroupDetailStatusList: state => state.appraisalGroupDetailStatusList,
  orgTypeList: state => state.orgTypeList,
  selectDatas: state => state.selectDatas,
  sysConfig: state => state.sysConfig,
  assetsTypeList: state => state.assetsTypeList,
  assetsList: state => state.assetsList,
  goodsList: state => state.goodsList
}

const actions = {
  async loadUserList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'userList', request: userRequest.get, mutation: 'setUserList', dealSourceData})
  },
  setUserList ({ commit }, data) {
    commit('setUserList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'userList')
  },
  async loadRoleList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList.forEach(v => v.name = v.name === 'admin' ? '超级管理员' : v.name)
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'roleList', request: roleRequest.get, mutation: 'setRoleList', dealSourceData})
  },
  setRoleList ({ commit }, data) {
    commit('setRoleList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'roleList')
  },
  async loadMenuList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList = tools.treeToList(dataList, 'children')
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'menuList', request: menuRequest.get, mutation: 'setMenuList', dealSourceData})
  },
  setMenuList ({ commit }, data) {
    commit('setMenuList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'menuList')
  },
  async loadFunList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['funKey', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'funList', request: funRequest.get, mutation: 'setFunList', dealSourceData})
  },
  setFunList ({ commit }, data) {
    commit('setFunList', dealData(data, 'id', ['funKey', 'name']))
    commit('updateBaseDataLoaded', 'funList')
  },
  async loadWorkerList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['code', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'workerList', request: workerRequest.get, mutation: 'setWorkerList', dealSourceData})
  },
  setWorkerList ({ commit }, data) {
    commit('setWorkerList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'workerList')
  },
  async loadAllWorkerList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['code', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'allWorkerList', request: workerRequest.get, mutation: 'setAllWorkerList', dealSourceData})
  },
  setAllWorkerList ({ commit }, data) {
    commit('setAllWorkerList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'allWorkerList')
  },
  async loadOrgList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'orgList', request: orgRequest.getAll, mutation: 'setOrgList', dealSourceData})
  },
  setOrgList ({ commit }, data) {
    commit('setOrgList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'orgList')
  },
  async loadOrgTree ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dataList
    }
    return await loadData({commit, getters, reLoad, dataKey: 'orgTree', request: orgRequest.getAllTree, mutation: 'setOrgTree', dealSourceData})
  },
  setOrgTree ({ commit }, data) {
    commit('setOrgTree', data)
    commit('updateBaseDataLoaded', 'orgTree')
  },
  async loadOrgTypeList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList.sort((a, b) => a.sortNum - b.sortNum)
      return dealData(dataList, 'id', ['level', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'orgTypeList', request: orgTypeRequest.get, mutation: 'setOrgTypeList', dealSourceData})
  },
  setOrgTypeList ({ commit }, data) {
    commit('setOrgTypeList', dealData(data, 'id', ['level', 'name']))
    commit('updateBaseDataLoaded', 'orgTypeList')
  },
  async loadAssetsTypeList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dataList
    }
    return await loadData({commit, getters, reLoad, dataKey: 'assetsTypeList', request: assetsTypeRequest.getTree, mutation: 'setAssetsTypeList', dealSourceData})
  },
  setAssetsTypeList ({ commit }, data) {
    commit('setAssetsTypeList', data)
    commit('updateBaseDataLoaded', 'assetsTypeList')
  },
  async loadAssetsList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['code', 'name', 'ggxh'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'assetsList', request: assetsRequest.get, mutation: 'setAssetsList', dealSourceData})
  },
  setAssetsList ({ commit }, data) {
    commit('setAssetsList', data)
    commit('updateBaseDataLoaded', 'assetsList')
  },
  async loadGoodsList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['id', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'goodsList', request: goodsRequest.get, mutation: 'setGoodsList', dealSourceData})
  },
  setGoodsList ({ commit }, data) {
    commit('setGoodsList', dealData(data, 'id', ['id', 'name']))
    commit('updateBaseDataLoaded', 'goodsList')
  },
  async loadSelectDatas ({ commit, getters }, reLoad) {
    if (reLoad || Object.keys(getters.selectDatas).length === 0) {
      let res = await configsRequest.get({type: 'selectData'})
      let dataMap = {}
      Config.selectData.forEach(v => {
        let value = res.find(v1 => v1.configKey === v.key)
        if (value) {
          value = JSON.parse(value.value)
          if (value.length > 0) {
            value = value.map(v1 => {
              return {
                key: v1.value,
                label: v1.label
              }
            })
          }
        }
        dataMap[v.key] = value && value.length > 0 ? value : v.defaultValue
      })
      commit('setSelectDatas', dataMap)
    }
    return getters.selectDatas
  },
  async loadSysConfig ({ commit, getters }) {
    let res = await configsRequest.get({type: 'sysConfig'})
    let dataMap = {}
    Config.sysConfig.forEach(v => {
      let value = res.find(v1 => v1.configKey === v.key)
      if (value && value.value) {
        value = JSON.parse(value.value)
      } else {
        value = v.defaultValue
      }
      dataMap[v.key] = value
    })
    commit('setSysConfig', dataMap)
    return getters.sysConfig
  }
}

const mutations = {
  updateBaseDataLoading (state, {key, value}) {
    state.baseDataLoading[key] = value
  },
  updateBaseDataLoaded (state, key) {
    state.baseDataLoaded[key] = true
  },
  setMenuList (state, data) {
    state.menuList = data
  },
  setFunList (state, data) {
    state.funList = data
  },
  setRoleList (state, data) {
    state.roleList = data
  },
  setUserList (state, data) {
    state.userList = data
  },
  setOrgList (state, data) {
    state.orgList = data
  },
  setAllWorkerList (state, data) {
    state.allWorkerList = data
  },
  setWorkerList (state, data) {
    state.workerList = data
  },
  setOrgTypeList (state, data) {
    state.orgTypeList = data
  },
  setOrgTree (state, data) {
    state.orgTree = data
  },
  setSelectDatas (state, data) {
    state.selectDatas = data
  },
  setSysConfig (state, data) {
    state.sysConfig = data
  },
  setAssetsTypeList (state, data) {
    state.assetsTypeList = data
  },
  setAssetsList (state, data) {
    state.assetsList = data
  },
  setGoodsList (state, data) {
    state.goodsList = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
