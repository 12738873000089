export default {
  selectData: [],
  sysConfig: [{
    key: 'envName',
    label: '环境名称',
    remark: '当前环境名称',
    defaultValue: '',
    editConfig: {
      type: 'input'
    }
  },
  {
    key: 'env',
    label: '环境',
    remark: '当前环境',
    defaultValue: '',
    editConfig: {
      type: 'input'
    }
  }]
}
