<style scoped lang="less">
  .img-preview {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, .6);
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
  }
  .tools {
    z-index: 1;
    padding: 0 20px;
    line-height: 40px;
    background-color: #000;
    color: #FFF;
  }
  .view {
    height: 0;
    flex: 1;
    margin: 10px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    img {
      opacity: 0;
      height: 100%;
      display: block;
      margin: 0 auto;
    }
  }
  .vlist {
    z-index: 1;
    background-color: #000;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .left, .right {
      cursor: pointer;
      border-radius: 5px;
      background-color: #6d6f70;
      border: 1px solid #6d6f70;
      padding: 20px;
      transition: all .2s;
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, .0) inset;
      &:before {
        transition: all .2s;
        position: relative;
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 10px solid transparent;
      }
      &:hover {
        box-shadow: 0 0 10px 0 rgba(255, 255, 255, .3) inset;
        &.left:before {
          border-right-color: rgb(185, 185, 185);
        }
        &.right:before {
          border-left-color: rgb(185, 185, 185);
        }
      }
      &.disable {
        pointer-events: none;
        opacity: 0.3;
      }
    }
    .left:before {
      left: -5px;
      border-right-color: #000;
    }
    .right:before {
      right: -5px;
      border-left-color: #000;
    }
  }
  .cover-list {
    flex: 1;
    margin: 0 20px;
    overflow: auto;
    .transform {
      padding: 20px 0;
      img {
        cursor: pointer;
        object-fit: cover;
        height: 80px;
        width: 130px;
        box-shadow: 0 0 10px 0 transparent;
        transition: all .2s;
        border: 1px solid #FFF;
        border-radius: 5px;
        &:hover, &.active {
          box-shadow: 0 0 10px 0 #FFF;
          transform: scale(1.05, 1.05);
          z-index: 1;
        }
        &.active {
          border-color: #1786fc;
        }
        & + img {
          margin-left: 15px;
        }
      }
    }
  }
  .tools {
    display: flex;
    justify-content: space-between;
    .action {
      display: flex;
      align-items: center;
    }
    span.btn  {
      user-select: none;
      cursor: pointer;
      font-size: 15px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      color: #FFF;
      & + span.btn {
        margin-left: 15px;
      }
      &:hover, &.active {
        color: rgb(23, 134, 252);
      }
    }
    i.iconfont {
      margin-right: 5px;
      font-size: 18px;
    }
  }
  .poptip {
    color: #657180;
    white-space: nowrap;
    word-break: keep-all;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
  .pop-doc-page {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
    border-radius: 8px;
    z-index: 9;
  }
</style>

<template>
  <div class="img-preview" v-show="show">
    <div class="tools">
      <span>图片预览</span>
      <div class="action">
        <fm-poptip trigger="hover" arrow>
          <div class="btn-group">
            <span class="btn" :class="{active: dragMode === 'crop'}" @click="setCropper('setDragMode', 'crop')">
              <i class="iconfont icon-crop"></i>裁剪
            </span>
            <span class="btn" :class="{active: dragMode === 'move'}" @click="setCropper('setDragMode', 'move')">
              <i class="iconfont icon-move"></i>移动
            </span>
          </div>
          <div class="poptip" slot="content"><i class="iconfont icon-a-2"></i>双击下方空白区域可切换</div>
        </fm-poptip>
        <span style="margin-left: 15px;" class="btn" @click="setCropper('scaleX')"><i class="iconfont icon-x-reverse"></i> 水平翻转</span>
        <span class="btn" @click="setCropper('scaleY')"><i class="iconfont icon-y-reverse"></i> 垂直翻转</span>
        <span class="btn" @click="setCropper('rotate', -90)"><i class="iconfont icon-right-rotate"></i>向左旋转</span>
        <!-- <fm-poptip style="margin-left: 10px;" arrow>
          <span class="btn"><i class="iconfont icon-right-rotate"></i>向左旋转</span>
          <div slot="content" style="width: 300px; padding: 0 20px;">
            <el-slider :format-tooltip="v => '向左旋转' + v + '度'" :min="0" :max="360" v-model="transform.rotate.left" @change="(v) => setCropper('rotateTo', -v)" :value="0"></el-slider>
          </div>
        </fm-poptip> -->
        <span class="btn" @click="setCropper('rotate', 90)"><i class="iconfont icon-left-rotate"></i>向右旋转</span>
        <!-- <fm-poptip style="margin-left: 10px;" arrow>
          <span class="btn"><i class="iconfont icon-left-rotate"></i>向右旋转</span>
          <div slot="content" style="width: 300px; padding: 0 20px;">
            <el-slider :format-tooltip="v => '向右旋转' + v + '度'" :min="0" :max="360" v-model="transform.rotate.right" @change="(v) => setCropper('rotateTo', v)" :value="0"></el-slider>
          </div>
        </fm-poptip> -->
        <fm-poptip trigger="hover" arrow style="margin-left: 10px;">
          <span @click="setCropper('zoom', 0.1)" class="btn"><i class="iconfont icon-scale-big"></i>放大</span>
          <span @click="setCropper('zoom', -0.1)" class="btn"><i class="iconfont icon-scale-small"></i>缩小</span>
          <div class="poptip" slot="content"><i class="iconfont icon-a-2"></i>使用鼠标滚轮也可进行缩放</div>
        </fm-poptip>
        <span class="btn" style="margin-left: 10px;" @click="setCropper('reset')"><i class="iconfont icon-reset"></i>重置</span>
        <span class="btn" @click="print"><i class="iconfont icon-printer"></i>打印</span>
        <span class="btn" @click="allPrint"><i class="iconfont icon-printer"></i>批量打印</span>
      </div>
      <span class="btn" @click="quit">退出</span>
    </div>
    <div class="view">
      <img ref="img" :src="currentSrc" :alt="active ? imgs[active] : undefined" />
    </div>
    <div class="vlist">
      <div class="left" :class="{disable: active <= 0}" @click="seek(-1)"></div>
      <div class="cover-list">
        <div class="transform">
          <img v-for="(item, i) in imgs" @click="active = i" :class="{active: active === i}" :key="item + i" :src="source[i] || undefined" :alt="item" :title="item" />
        </div>
      </div>
      <div class="right" :class="{disable: active >= (imgs.length - 1)}" @click="seek(1)"></div>
    </div>
    <iframe ref="iframe" style="display: none;" />

    <div class="pop-doc-page" v-if="status.print">
      <print @quit="status.print = false" :source="source" />
    </div>
  </div>
</template>

<script>
import { fileRequest } from '@/api'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import Print from './print'

function iframePinrt () {
  this.contentWindow.print()
}

export default {
  components: { Print },
  props: {
    index: { type: Number, default: null },
    imgs: { type: Array, default: () => ([]) },
    show: { type: Boolean, default: false },
    isSource: { type: Boolean, default: false }
  },
  data () {
    return {
      active: this.index,
      source: [],
      $cropper: null,
      dragMode: 'move',
      status: {
        print: false
      },
      transform: {
        rotate: {
          left: 0,
          right: 0
        }
      }
    }
  },
  computed: {
    currentSrc () {
      return ![null, undefined].includes(this.active) && this.source[this.active] ? this.source[this.active] : null
    }
  },
  watch: {
    index (index) {
      this.active = index
    },
    imgs: {
      deep: true,
      handler () {
        this.loadImg()
      }
    },
    currentSrc: {
      immediate: true,
      handler (src) {
        if (this.$cropper) {
          this.$cropper.destroy()
          this.$cropper = null
        }
        if (src) {
          this.$nextTick(this.loadCropper)
        }
      }
    }
  },
  methods: {
    setCropper (action, ...arg) {
      if (this.$cropper) {
        const data = this.$cropper.getData()
        console.log(action, arg, data)
        if (action === 'setDragMode') {
          this.dragMode = arg[0]
        } else if (action === 'scaleY') {
          arg = [data.scaleY === -1 ? 1 : -1]
        } else if (action === 'scaleX') {
          arg = [data.scaleX === -1 ? 1 : -1]
        } else if (action === 'rotateTo') {
          const rotateTo = arg[0]
          if (rotateTo > 0) {
            arg = [data.rotate < 0 ? (Math.abs(data.rotate) + rotateTo) : (rotateTo - data.rotate)]
          } else {
            arg = [data.rotate > 0 ? (-data.rotate - rotateTo) : (rotateTo - data.rotate)]
          }
        }
        this.$cropper[action].apply(this.$cropper, arg)
      }
    },
    seek (action) {
      const active = this.active + action
      if (active < 0) {
        this.active = 0
      } else if (active >= this.imgs.length) {
        this.active = this.imgs.length - 1
      } else {
        this.active = active
      }
    },
    allPrint () {
      this.status.print = true
    },
    print () {
      this.$cropper.getCroppedCanvas().toBlob((blob) => {
        this.$refs.iframe.srcdoc = '<img style="width: 100%; height: 100%;object-fit: contain;" src="' + window.URL.createObjectURL(blob) + '" />'
      }, 'image/png')
      this.$refs.iframe.removeEventListener('load', iframePinrt)
      this.$refs.iframe.addEventListener('load', iframePinrt)
    },
    loadCropper () {
      if (this.$cropper) {
        this.$cropper.destroy()
      }
      this.transform.rotate.left = 0
      this.transform.rotate.right = 0
      this.$nextTick(() => {
        this.$cropper = new Cropper(this.$refs.img, {
            viewMode: 1,
            background: false,
            autoCrop: false,
            autoCropArea: 1,
            zoomable: true,
            zoomOnTouch: false,
            zoomOnWheel: true,
            dragMode: this.dragMode
        })
      })
    },
    async loadImg () {
      this.source = new Array(this.imgs.length).fill(null)
      const loading = this.imgs.map((path, index) => {
        if (this.isSource) {
          this.source.splice(index, 1, path)
          return Promise.resolve(path)
        } else {
          return new Promise(async resolve => {
            const data = await fileRequest.download({
              path: path
            })
            const url = window.URL.createObjectURL(new Blob([data]))
            this.source.splice(index, 1, url)
            resolve(url)
          })
        }
      })
      return await Promise.all(loading)
    },
    quit () {
      this.$emit('close')
    }
  },
  mounted () {
    this.loadImg()
  }
}
</script>
