
const generateBasisUrl = function (url) {
  return Object.freeze({
    basUrl: url,
    parmUrl: (id) => url + `/${id}`,
  })
}

export const auth = Object.freeze({
  login: '/ucenter/oauth/token',
  getPubKey: '/ucenter/oauth/pre/token',
  getInfo: '/ucenter/user/current',
  logOut: '/ucenter/oauth/token',
  updatePwd: '/ucenter/user/myPassword'
})

export const menuApis = Object.freeze({
  parmUrl: (id) => `/ucenter/system/menus/${id}/apis`
})

export const userRole = Object.freeze({
  parmUrl: (userId) => `/ucenter/user/${userId}/roles`
})

export const resetPassword = Object.freeze({
  parmUrl: (userId) => `/ucenter/user/${userId}/password/default`
})

export const systemApis = Object.freeze({
  url: '/ucenter/system/apis'
})

export const refQuery = Object.freeze({
  get: (key) => `/internalcontrol/data/ref/query/${key}`
})

export const user = generateBasisUrl('/ucenter/user')

export const role = generateBasisUrl('/ucenter/system/roles')

export const fun = Object.assign({}, generateBasisUrl('/ucenter/system/funs'), {
  updateApi: (id) => `/ucenter/system/funs/${id}/apis`
})

export const menu = generateBasisUrl('/ucenter/system/menus')

export const data = Object.freeze({
  get: (name) => `/internalcontrol/data/${name}`,
  add: (name) => `/internalcontrol/data/${name}`,
  update: (name, id) => `/internalcontrol/data/${name}/${id}`,
  del: (name, id) => `/internalcontrol/data/${name}/${id}`,
})

export const common = Object.freeze({
  get: (name) => `/internalcontrol/${name}`,
  add: (name) => `/internalcontrol/${name}`,
  update: (name, id) => `/internalcontrol/${name}/${id}`,
  del: (name, id) => `/internalcontrol/${name}/${id}`,
})

export const file = Object.freeze({
  mkdir: '/fms/file/mkdir',
  upload: '/fms/upload',
  download: '/fms/download',
  del: '/fms/file'
})


export const ucenter = Object.freeze({
  authorities: (id) => `/ucenter/user/${id}/authorities`,
  users: '/ucenter/user'
})

export const operationLog = Object.freeze({
  get: '/internalcontrol/operation_log'
})

export const dataAuditLog = Object.freeze({
  base: '/internalcontrol/data/audit/log'
})

export const workOperation = Object.freeze({
  log: '/internalcontrol/operation/log'
})

export const org = Object.freeze({
  getTree: '/internalcontrol/org/tree',
  getAllTree: '/internalcontrol/org/all_tree',
  getAll: '/internalcontrol/org/all'
})

export const worker = Object.freeze({
  getDetail: '/internalcontrol/worker/detail',
})

export const budgetYear = Object.freeze({
  get: '/internalcontrol/budget/year',
  add: '/internalcontrol/budget/year',
  update: (id) => `/internalcontrol/budget/year/${id}`,
  // check: (id) => `/internalcontrol/budget/year/check/${id}`
  start: (id) => `/internalcontrol/budget/year/start/${id}`,
  end: (id) => `/internalcontrol/budget/year/end/${id}`
})

export const budgetDetail = Object.freeze({
  update: (id) => `/internalcontrol/budget/detail/${id}`,
  add: '/internalcontrol/budget/detail',
  getApply: '/internalcontrol/budget/detail/apply',
  submit: (id) => `/internalcontrol/budget/detail/submit/${id}`,
  submitBack: (id) => `/internalcontrol/budget/detail/submit/back/${id}`,
  firstCheck: (id) => `/internalcontrol/budget/detail/first_check/${id}`,
  firstCheckBack: (id) => `/internalcontrol/budget/detail/first_check/back/${id}`,
  lastCheck: (id) => `/internalcontrol/budget/detail/last_check/${id}`,
  lastCheckBack: (id) => `/internalcontrol/budget/detail/last_check/back/${id}`
})

export const purchaseApply = Object.freeze({
  get: '/internalcontrol/purchase/apply',
  update: (id) => `/internalcontrol/purchase/apply/${id}`,
  submit: (id) => `/internalcontrol/purchase/apply/submit/${id}`,
  start: (id) => `/internalcontrol/purchase/apply/start/${id}`,
  recall: (id) => `/internalcontrol/purchase/apply/recall/${id}`,
  check: (id) => `/internalcontrol/purchase/apply/check/${id}`,
  back: (id) => `/internalcontrol/purchase/apply/back/${id}`
})

export const purchase = Object.freeze({
  get: '/internalcontrol/purchase',
  update: (id) => `/internalcontrol/purchase/${id}`,
  submit: (id) => `/internalcontrol/purchase/submit/${id}`,
  start: (id) => `/internalcontrol/purchase/start/${id}`,
  recall: (id) => `/internalcontrol/purchase/recall/${id}`,
  check: (id) => `/internalcontrol/purchase/check/${id}`,
  back: (id) => `/internalcontrol/purchase/back/${id}`,
  type: (id) => `/internalcontrol/purchase/type/${id}`
})

export const purchaseDetail = Object.freeze({
  get: '/internalcontrol/purchase/detail',
  update: (id) => `/internalcontrol/purchase/detail/${id}`,
  delType: (id) => `/internalcontrol/purchase/detail/del/type/${id}`,
})
