import router from '@/router'

import {
  localstorageHelper
} from '@/fmlib'

import {
  userLogOut,
  getUserInfo
} from '@/api'

import Vue from 'vue'

const state = {
  token: null,
  wxCode: null,
  isWxUse: false,
  authPass: false,
  jumpPageParm: {},
  authIng: false,
}

const getters = {
  token: state => state.token,
  authPass: state => state.authPass,
  jumpPageParm: state => state.jumpPageParm,
  authIng: state => state.authIng
}

const findMenu = (menus, name) => {
  let find = false
  let i = 0
  while (i < menus.length) {
    let v = menus[i]
    if (v.url === name) {
      find = true
      break
    }
    find = findMenu(v.children, name)
    if (find) {
      find = true
      break
    }
    i += 1
  }
  return find
}

const actions = {
  setAuthing ({ commit }, data) {
    commit('setAuthing', data)
  },
  setJumpPageParm ({ commit }, data) {
    commit('setJumpPageParm', data)
  },
  async authInit ({ commit, dispatch, getters }) {
    // 获取token
    // 1.本地存储获取  3.跳转登录页面登录
    dispatch('setAuthing', true)
    let token = localstorageHelper.getData('token')
    commit('setToken', token)
    let authPass = true
    if (getters.token) {
      // 获取用户信息
      try {
        let data = await getUserInfo()
        dispatch('setUserData', data)
      } catch (error) {
        commit('setToken', '')
        authPass = false
      }
    } else {
      authPass = false
    }
    commit('setAuthPass', authPass)
    if (getters.authPass) {
      await Vue.prototype.$loadTableConfigs('table')
      await dispatch('loadSysConfig')
      await dispatch('loadSelectDatas')
      dispatch('goFirstPage')
    } else {
      dispatch('userOAuth')
    }
    dispatch('setAuthing', false)
  },
  userOAuth () {
    // 用户赋权
    router.push({name: 'login'})
  },
  logOut ({ dispatch, commit }) {
    userLogOut()
    commit('setToken', '')
    dispatch('setUserInfo', {})
    let routerData = {
      name: 'login',
      params: {
        logOut: 1
      }
    }
    router.push(routerData)
    setTimeout(() => {
      window.location.reload()
    }, 200)
  },
  goFirstPage ({ getters }) {
    if (getters.clientType === 'wap') {
      if (getters.jumpPageParm.type === 'href' && getters.jumpPageParm.value.includes('wap/checking')) {
        window.location.href = getters.jumpPageParm.value
        return
      }
      if (getters.jumpPageParm.name === 'wap.checking') {
        router.push(getters.jumpPageParm)
        return
      }
      router.push({'name': 'wap.menu'})
      return
    }
    if (getters.jumpPageParm.value || getters.jumpPageParm.path || getters.jumpPageParm.name) {
      if (getters.jumpPageParm.type === 'href') {
        window.location.href = getters.jumpPageParm.value
      } else if (findMenu(getters.availableMenus, getters.jumpPageParm.name) || ['budget.details', 'purchase.details', 'purchase_method.details'].includes(getters.jumpPageParm.name)) {
        router.push(getters.jumpPageParm)
      } else {
        router.push(getters.userFirstPage)
      }
    } else {
      router.push(getters.userFirstPage)
    }
  }
}

const mutations = {
  setAuthPass (state, data) {
    state.authPass = data
  },
  setAuthing (state, data) {
    state.authing = data
  },
  setJumpPageParm (state, data) {
    state.jumpPageParm = data
  },
  setAuthBeforeRouter (state, data) {
    state.authBeforeRouter = data
  },
  setToken (state, data) {
    localstorageHelper.updateData('token', data)
    state.token = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
