import {
  localstorageHelper
} from '@/fmlib'

const state = {
  userInfo: {},
  availableMenus: [],
  availableRoles: [],
  availableFuns: [],
  currentRole: null,
  userPrevilege: [],
  overviewRole: localStorage.getItem('overviewRole') === 'true'
}

const getters = {
  userInfo: state => state.userInfo,
  userPrevilege: state => state.userPrevilege,
  availableMenus: state => state.availableMenus,
  availableFuns: state => state.availableFuns,
  availableFunKeys: state => {
    return state.availableFuns.map(v => v.funKey)
  },
  availableRoles: state => state.availableRoles,
  currentRole: state => state.currentRole,
  isWorker: (state) => {
    return state.availableRoles.find(v => v.name === '员工')
  },
  userFirstPage: (state) => {
    let availableMenus = state.availableMenus
    let goMenu = null
    if (availableMenus && availableMenus.length > 0) {
      goMenu = availableMenus[0]
      if (!goMenu.url && goMenu.children && goMenu.children.length > 0) {
        goMenu = goMenu.children[0]
        if (!goMenu.url && goMenu.children && goMenu.children.length > 0) {
          goMenu = goMenu.children[0]
        }
      }
    }
    return {name: goMenu && goMenu.url ? goMenu.url : 'home'}
  },
  defaultRole: state => state.availableRoles.length > 0 ? state.availableRoles[0] : null,
  overviewRole: state => state.overviewRole
}

const actions = {
  setUserData ({ dispatch }, data) {
    dispatch('setUserInfo', {
      name: data.name,
      id: data.id
    })
    dispatch('setAvailableRoles', data.roles || [])
    let roleId = localstorageHelper.getData('roleId')
    if (data.roles && data.roles.length > 0) {
      if (roleId && data.roles.map(v => v.id).includes(roleId)) {
        dispatch('setCurrentRole', roleId)
      } else {
        dispatch('setCurrentRole', data.roles[0].id)
      }
    }
  },
  setUserInfo ({ commit }, data) {
    commit('setUserInfo', data)
  },
  setUserPrevilege ({ commit }, data) {
    commit('setUserPrevilege', data)
  },
  setAvailableMenus ({ commit }, data) {
    commit('setAvailableMenus', data)
  },
  setAvailableFuns ({ commit }, data) {
    commit('setAvailableFuns', data)
  },
  setAvailableRoles ({ commit }, data) {
    commit('setAvailableRoles', data)
  },
  setCurrentRole ({ commit, getters, dispatch }, roleId) {
    let role = getters.availableRoles.find(v => v.id === roleId)
    if (role && role.id) {
      localstorageHelper.updateData('roleId', roleId)
    }
    commit('setCurrentRole', role)
    dispatch('setAvailableMenus', role && role.menus ? role.menus.filter(v => v.clientType === getters.clientType) : [])
    dispatch('setAvailableFuns', role && role.funs ? role.funs : [])
    dispatch('setUserPrevilege', role && role.authorities ? role.authorities : [])
  },
  setOverviewRole ({ commit }, val) {
    commit('setOverviewRole', val)
  }
}

const mutations = {
  setUserInfo (state, data) {
    state.userInfo = data
  },
  setUserPrevilege (state, data) {
    state.userPrevilege = data
  },
  setAvailableMenus (state, data) {
    state.availableMenus = data
  },
  setAvailableFuns (state, data) {
    state.availableFuns = data
  },
  setAvailableRoles (state, data) {
    state.availableRoles = data
  },
  setCurrentRole (state, data) {
    state.currentRole = data
  },
  setOverviewRole (state, data) {
    state.overviewRole = data
    localStorage.setItem('overviewRole', data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
