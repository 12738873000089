import {
  axiosInit
} from '@/fmlib'
import * as url from './url'

import sysEnv from '@/env/env'

import store from '@/store'

import Vue from 'vue'

export const baseUrl = sysEnv.apiAdr

let showMessageFun = (msg) => {
  Vue.prototype.$notice.error({
    title: '系统提示',
    desc: msg
  })
}

let showMessageFunNoNotice = (msg) => {
  console.log('api调用异常:', msg)
}

export const getHeaderParm = () => {
  return {
    token: store.getters.token,
    roleIds: store.getters.currentRole ? store.getters.currentRole.id : null
  }
}

export const getHeaderParmAllRole = () => {
  return {
    token: store.getters.token,
    roleIds: store.getters.availableRoles ? store.getters.availableRoles.map(v => v.id).join(',') : null
  }
}

let axiosInitOne = new axiosInit.axiosInit({baseUrl, showMessageFun, getHeaderParm})
let axiosInitOneAllRole = new axiosInit.axiosInit({baseUrl, showMessageFun, getHeaderParm: getHeaderParmAllRole})
let axiosInitOneNoNotice = new axiosInit.axiosInit({baseUrl, showMessageFun: showMessageFunNoNotice, getHeaderParm})

export const request = axiosInitOne.request
export const requestAllRole = axiosInitOneAllRole.request
export const requestNoNotice = axiosInitOneNoNotice.request

let httpRequestMethods = axiosInitOne.httpRequestMethods

// file
export const fileRequest = {
  mkdir: function (parm) {
    return request(url.file.mkdir, httpRequestMethods.POST, parm)
  },
  getFileData: function (parm) {
    return request('/fms/file', httpRequestMethods.GET, parm)
  },
  upload: function (parm) {
    return request(url.file.upload, httpRequestMethods.POST, parm, {
      contentType: 'multipart/form-data'
    })
  },
  get: function (parm) {
    return requestNoNotice(url.file.download, httpRequestMethods.GET, parm)
  },
  download: function (parm) {
    return request(url.file.download, httpRequestMethods.GET, parm, {
      responseType: 'blob'
    })
  },
  del: function (parm) {
    return request(url.file.del, httpRequestMethods.DELETE, parm)
  },
  deal: function () {
    return request('/fms/file/deal', httpRequestMethods.GET, {})
  }
}

export const generateBasisRequest = function (urlParm) {
  return {
    get: function (parm) {
      return request(urlParm.basUrl, httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(urlParm.basUrl, httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(urlParm.parmUrl(id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(urlParm.parmUrl(id), httpRequestMethods.DELETE)
    }
  }
}

export const generateBasisDataRequest = function (name) {
  return {
    get: function (parm) {
      return request(url.data.get(name), httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(url.data.add(name), httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(url.data.update(name, id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(url.data.del(name, id), httpRequestMethods.DELETE)
    }
  }
}

export const generateCommonRequest = function (name) {
  return {
    get: function (parm) {
      return request(url.common.get(name), httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(url.common.add(name), httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(url.common.update(name, id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(url.common.del(name, id), httpRequestMethods.DELETE)
    }
  }
}

export const refQueryRequestHander = async function (key, parm) {
  return await request(url.refQuery.get(key), httpRequestMethods.GET, parm)
}

// systemApis
export function systemApisGet () {
  return request(url.systemApis.url, httpRequestMethods.GET)
}

// menuApis
export function menuApisUpate (id, parm) {
  return request(url.menuApis.parmUrl(id), httpRequestMethods.POST, parm)
}

export const userRequest = generateBasisRequest(url.user)

export const roleRequest = generateBasisRequest(url.role)

export const funRequest = Object.assign(generateBasisRequest(url.fun), {
  updateApi (id, parm) {
    return request(url.fun.updateApi(id), httpRequestMethods.POST, parm)
  }
})

export const menuRequest = generateBasisRequest(url.menu)

// auth
export function login (parm) {
  return request(url.auth.login, httpRequestMethods.POST, parm)
}

export function getLoginPubKey (parm) {
  return request(url.auth.getPubKey, httpRequestMethods.POST, parm)
}

export function updatePwd (parm) {
  return request(url.auth.updatePwd, httpRequestMethods.PUT, parm)
}

export function loginUpdatePwd (parm, token) {
  let get = () => {
    return {
      token
    }
  }
  return new axiosInit.axiosInit({baseUrl, showMessageFun, getHeaderParm: get}).request(url.auth.updatePwd, httpRequestMethods.PUT, parm)
}

export function userLogOut () {
  return request(url.auth.logOut, httpRequestMethods.DELETE)
}

export function getUserInfo () {
  return request(url.auth.getInfo, httpRequestMethods.GET)
}

// resetPassword
export function resetPassword (id) {
  return request(url.resetPassword.parmUrl(id), httpRequestMethods.PUT)
}

export const ucenterRequest = {
  updateAuthorities (id, parm) {
    return request(url.ucenter.authorities(id), httpRequestMethods.POST, parm)
  },
  getAuthorities (parm) {
    return request(url.ucenter.users, httpRequestMethods.GET, parm)
  }
}

export const dataAuditLogReuqest = {
  get: function (parm) {
    return request(url.dataAuditLog.base, httpRequestMethods.GET, parm)
  }
}

export const workOperationReuqest = {
  getLog: function (parm) {
    return request(url.workOperation.log, httpRequestMethods.GET, parm)
  }
}

// confQuery
export const confQueryRequest = generateCommonRequest('conf_query')

export const workerRequest = generateCommonRequest('worker')

export const orgTypeRequest = generateCommonRequest('org_type')

export const orgRequest = Object.assign(generateCommonRequest('org'), {
  getAll (parm) {
    return request(url.org.getAll, httpRequestMethods.GET, parm)
  },
  getTree (parm) {
    return request(url.org.getTree, httpRequestMethods.GET, parm)
  },
  getAllTree (parm) {
    return request(url.org.getAllTree, httpRequestMethods.GET, parm)
  }
})

// assets
export const assetsRequest = Object.assign(generateCommonRequest('assets'), {
  getProcess (id) {
    return request('/internalcontrol/assets/' + id, httpRequestMethods.GET)
  }
})

// assetsCheckRecord
export const assetsCheckRecordRequest = {
  get (parm) {
    return request('/internalcontrol/assets_check_record', httpRequestMethods.GET, parm)
  },
  check (parm) {
    return request('/internalcontrol/assets_check_record', httpRequestMethods.POST, parm)
  }
}

// goods
export const goodsRequest = generateCommonRequest('goods')

// assetsType
export const assetsTypeRequest = Object.assign(generateCommonRequest('assets_type'), {
  getTree (parm) {
    return request('/internalcontrol/assets_type/tree', httpRequestMethods.GET, parm)
  }
})

// assetsIn
export const assetsInRequest = generateCommonRequest('assets_in')

// assetsRepair
export const assetsRepairRequest = generateCommonRequest('assets_repair')

// assetsRepairApply
export const assetsRepairApplyRequest = Object.assign(generateCommonRequest('assets_repair_apply'), {
  switchStatus (id, parm) {
    return request('/internalcontrol/assets_repair_apply/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/internalcontrol/assets_repair_apply/by_auth', httpRequestMethods.GET, parm)
  },
  files (id, parm) {
    return request('/internalcontrol/assets_repair_apply/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getUpcoming () {
    return requestAllRole('/internalcontrol/assets_repair_apply/upcoming', httpRequestMethods.POST)
  }
})

// assetsScrapApply
export const assetsScrapApplyRequest = Object.assign(generateCommonRequest('assets_scrap_apply'), {
  switchStatus (id, parm) {
    return request('/internalcontrol/assets_scrap_apply/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/internalcontrol/assets_scrap_apply/by_auth', httpRequestMethods.GET, parm)
  },
  files (id, parm) {
    return request('/internalcontrol/assets_scrap_apply/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getUpcoming () {
    return requestAllRole('/internalcontrol/assets_scrap_apply/upcoming', httpRequestMethods.POST)
  }
})

// budgetYear
export const budgetYearRequest = {
  get (parm) {
    return request(url.budgetYear.get, httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request(url.budgetYear.add, httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request(url.budgetYear.update(id), httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request(url.budgetYear.update(id), httpRequestMethods.DELETE)
  },
  files (id, parm) {
    return request('/internalcontrol/budget/year/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  switchStatus (id, status) {
    return request('/internalcontrol/budget/year/switch/' + id, httpRequestMethods.POST, { statusActionKey: status })
  },
  getUpcoming () {
    return requestAllRole('/internalcontrol/budget/year/upcoming', httpRequestMethods.POST)
  }
}

// budget
export const budgetRequest = {
  get (parm) {
    return request('/internalcontrol/budget', httpRequestMethods.GET, parm)
  },
  getAll (parm) {
    return request('/internalcontrol/budget/all', httpRequestMethods.GET, parm)
  }
}

// budgetDetail
export const budgetDetailRequest = {
  get (parm) {
    return request('/internalcontrol/budget/detail', httpRequestMethods.GET, parm)
  },
  getByAuth (parm) {
    return request('/internalcontrol/budget/detail/by_auth', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request(url.budgetDetail.add, httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request(url.budgetDetail.update(id), httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request(url.budgetDetail.update(id), httpRequestMethods.DELETE)
  },
  switchStatus (id, parm) {
    return request('/internalcontrol/budget/detail/switch/' + id, httpRequestMethods.POST, parm)
  }
}

// purchaseContract
export const purchaseContractRequest = generateCommonRequest('purchase_contract')

// purchase
export const purchaseRequest = generateCommonRequest('purchase')

export const configsRequest = generateCommonRequest('sys_config')

export const statusManageRequest = {
  switchConfig (parm) {
    return request('/internalcontrol/status_manage/switch_config', httpRequestMethods.GET, parm)
  },
  config (parm) {
    return request('/internalcontrol/status_manage/config', httpRequestMethods.GET, parm)
  },
  reloadGroup () {
    return request('/internalcontrol/status_manage/reload', httpRequestMethods.GET)
  },
  getGroup () {
    return request('/internalcontrol/status_manage', httpRequestMethods.GET)
  },
  updateGroup (parm) {
    return request('/internalcontrol/status_manage', httpRequestMethods.POST, parm)
  },
  delGroup (id) {
    return request('/internalcontrol/status_manage/' + id, httpRequestMethods.DELETE)
  },
  updateStatus (parm) {
    return request('/internalcontrol/status_manage/status', httpRequestMethods.POST, parm)
  },
  delStatus (id) {
    return request('/internalcontrol/status_manage/status/' + id, httpRequestMethods.DELETE)
  },
  addSwitch (parm) {
    return request('/internalcontrol/status_manage/switch', httpRequestMethods.POST, parm)
  },
  updateSwitch (id, parm) {
    return request('/internalcontrol/status_manage/switch/' + id, httpRequestMethods.PUT, parm)
  },
  delSwitch (id) {
    return request('/internalcontrol/status_manage/switch/' + id, httpRequestMethods.DELETE)
  },
  addAction (parm) {
    return request('/internalcontrol/status_manage/action', httpRequestMethods.POST, parm)
  },
  updateAction (id, parm) {
    return request('/internalcontrol/status_manage/action/' + id, httpRequestMethods.PUT, parm)
  },
  delAction (id) {
    return request('/internalcontrol/status_manage/action/' + id, httpRequestMethods.DELETE)
  },
  addCondition (parm) {
    return request('/internalcontrol/status_manage/condition', httpRequestMethods.POST, parm)
  },
  updateCondition (id, parm) {
    return request('/internalcontrol/status_manage/condition/' + id, httpRequestMethods.PUT, parm)
  },
  delCondition (id) {
    return request('/internalcontrol/status_manage/condition/' + id, httpRequestMethods.DELETE)
  }
}

export const purchaseBatchRequest = Object.assign(generateCommonRequest('purchase_batch'), {
  switchStatus (id, parm) {
    return request('/internalcontrol/purchase_batch/switch/' + id, httpRequestMethods.POST, parm)
  },
  files (id, parm) {
    return request('/internalcontrol/purchase_batch/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getUpcoming () {
    return requestAllRole('/internalcontrol/purchase_batch/upcoming', httpRequestMethods.POST)
  }
})

export const purchaseBatchDetailsRequest = Object.assign(generateCommonRequest('purchase_batch/detail'), {
  switchStatus (id, parm) {
    return request('/internalcontrol/purchase_batch/detail/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/internalcontrol/purchase_batch/detail/by_auth', httpRequestMethods.GET, parm)
  }
})

export const purchaseMethodBatchRequest = Object.assign(generateCommonRequest('purchase_method_batch'), {
  switchStatus (id, parm) {
    return request('/internalcontrol/purchase_method_batch/switch/' + id, httpRequestMethods.POST, parm)
  },
  files (id, parm) {
    return request('/internalcontrol/purchase_method_batch/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getUpcoming () {
    return requestAllRole('/internalcontrol/purchase_method_batch/upcoming', httpRequestMethods.POST)
  }
})

export const purchaseMethodBatchDetailsRequest = Object.assign(generateCommonRequest('purchase_method_batch/detail'), {
  switchStatus (id, parm) {
    return request('/internalcontrol/purchase_method_batch/detail/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/internalcontrol/purchase_method_batch/detail/by_auth', httpRequestMethods.GET, parm)
  },
  files (id, parm) {
    return request('/internalcontrol/purchase_method_batch/detail/file_ids/' + id, httpRequestMethods.PUT, parm)
  }
})

export const billRequest = Object.assign(generateCommonRequest('bill'), {
  statusTime () {
    return request('/internalcontrol/bill/last_status_time', httpRequestMethods.GET)
  }
})

export const acceptanceRequest = Object.assign(generateCommonRequest('acceptance'), {
  accept (id, parm) {
    return request('/internalcontrol/acceptance/accept/' + id, httpRequestMethods.POST, parm)
  },
  getMy (parm) {
    return request('/internalcontrol/acceptance/my', httpRequestMethods.GET, parm)
  },
  getUpcoming () {
    return requestAllRole('/internalcontrol/acceptance/upcoming', httpRequestMethods.POST)
  }
})
